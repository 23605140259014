<template>
  <a-row>
    <a-form :model="Catalogue">
      <label for="Look Name">Look Name</label>
      <a-input class="mb-5" v-model:value="Catalogue.name" />
      <label for="Website url">Website url</label>
      <a-input class="mb-5" v-model:value="Catalogue.websiteURL" />
      <a-form-item class="my-5">
        <label for="image">Cover Image</label>
        <br />
        <a-input type="file" @change="handleImageChange" />
      </a-form-item>
      <a-form-item style="margin-left: 110px; border-radius: 5px">
        <app-button
          buttonText="Create Catalogue"
          :loading="requestLoading"
          @buttonAction="handleSubmit"
        />
      </a-form-item>
    </a-form>
  </a-row>
</template>
<script>
// import { UploadOutlined } from '@ant-design/icons-vue'
import { reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import useLoadingState from '@/use/loading-state'
import useNotifier from '@/use/notifier'
import AppButton from '@/components/base/AppButton.vue'
export default {
  components: { AppButton },
  props: {
    collection: {
      default: ''
    }
  },
  setup(props) {
    // external composition functions
    const { requestLoading } = useLoadingState()
    useNotifier()
    // end of composition functions
    const store = useStore()
    // local state
    const state = reactive({
      Catalogue: {
        name: '',

        image: '',
        websiteURL: ''
      }
    })
    // methods

    const handleImageChange = (e) => {
      let formData = new FormData()
      formData.append('photo', e.target.files[0])
      state.Catalogue.image = formData
    }

    const handleSubmit = () => {
      let look = {
        collection_id: props.collection,
        name: state.Catalogue.name,
        image: state.Catalogue.image,
        websiteURL: state.Catalogue.websiteURL
      }

      store
        .dispatch('collection/CreateLook', look)

        .catch((error) => {
          console.log('error during validation', error)
        })
    }

    // return variables and functions to be used within the template
    return {
      ...toRefs(state),
      handleSubmit,
      requestLoading,
      handleImageChange
    }
  }
}
</script>
