<template>
  <div>
    <Heading :collection-id="collectionId" />
    <!-- <ImageCard /> -->

    <a-card style="background-color: #ffffff; border-radius: 10px">
      <a-table :columns="columns" :data-source="looks">
        <template #tableImg="{ record }">
          <a-image
            :src="typeof record.image === 'object' ? '' : record.image"
            fallback="https://via.placeholder.com/100"
            class="w-36 h-32"
          />
        </template>

        <template #action="{ record }">
          <span>
            <a-dropdown>
              <template #overlay>
                <a-menu>
                  <a-menu-item @click="editLook(record)">Edit</a-menu-item>
                  <a-menu-item @click="deleteLook(record)">Delete</a-menu-item>
                </a-menu>
              </template>
              <a>
                <EllipsisOutlined style="font-size: 25px; color: #000" />
              </a>
            </a-dropdown>
          </span>
        </template>
      </a-table>
    </a-card>
  </div>
</template>
<script>
import Heading from './components/Header.vue'
import { useStore } from 'vuex'
import { reactive, toRefs, computed } from 'vue'
import { EllipsisOutlined } from '@ant-design/icons-vue'

const columns = [
  {
    title: 'Image',
    key: 'image',
    dataIndex: 'image',
    slots: { customRender: 'tableImg' }
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Action',
    key: 'action',
    slots: { customRender: 'action' }
  }
]

export default {
  components: {
    Heading,
    EllipsisOutlined
    // ImageCard
  },
  props: ['collectionId'],
  setup(props) {
    const store = useStore()

    const state = reactive({
      looks: [],
      columns
    })

    store.dispatch(`collection/FetchCollectionLooks`, props.collectionId)
    state.looks = computed(() => store.getters['collection/GetCollectionLooks'])
    const deleteLook = (look) => {
      let look_collection = {
        collection_id: props.collectionId,
        look_id: look._id
      }

      // console.log('deleting:' + JSON.stringify(look_collection, null, 2))

      store
        .dispatch('collection/DeleteLook', look_collection)

        .catch((error) => {
          console.log('error during validation', error)
        })
    }

    const previewLook = (look) => {
      console.log('previewing:' + JSON.stringify(look, null, 2))
    }

    const editLook = (look) => {
      console.log('editing:' + JSON.stringify(look, null, 2))
    }

    return { ...toRefs(state), deleteLook, previewLook, editLook }
  }
}
</script>
