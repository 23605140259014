import { useStore } from 'vuex'
import { computed, watch } from 'vue'
import { notification } from 'ant-design-vue'

export default function useNotifier() {
  const store = useStore()
  const notifications = computed(() => store.state.notify.notifications)
  const removeNotification = (notification) => {
    setTimeout(function () {
      store.dispatch('notify/remove', notification)
    }, 1000)
  }
  const openNotificationWithIcon = (notify) => {
    notification[notify.status]({
      message: notify.title,
      description: notify.message
    })
  }

  watch(
    () => [...notifications.value],
    (newNotifications) => {
      console.log(newNotifications)
      newNotifications.forEach((notify) => {
        //   console.log(notification)
        openNotificationWithIcon(notify)
        removeNotification(notify)
      })
    }
  )

  /*
   *  watcher to display notification when available
   * and also call removeNotification func to remove it from the store after displaying
   */
  return { notifications, removeNotification }
}
